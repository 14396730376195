import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Tooltip, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Alert } from 'reactstrap';
import * as Utilities from '../helpers/Utilities';
import * as EnterpriseService from '../service/Enterprise';
class BusinessInfo extends Component {
  state = { interval: null }

  loading = () => <div className="page-laoder">
    <div>
      <Loader type="Oval" color="#ed0000" height={50} width={50} />
      <div className="loading-label">Loading.....</div>
    </div>
  </div>

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      secureTextEntry: true,
      errorUsername: '',
      errorPassword: '',
      errorMsg: '',
      classDisplay: 'no-display',
      type: 'password',
      score: 'null',
      showEdit: true,
      storeName: '',
      businessInfo: { StoreName: "", SubDomain: "", PageName: "" },
      availableSubdomainInProcess: false,
      subDomainError: false,
    };


  }

  showEditFun = () => {
    this.setState({
      showEdit: !this.state.showEdit
    })

  }

  GetAvailableSubdmain = async (name) => {

    this.setState({ availableSubdomainInProcess: true });
    var response = await EnterpriseService.GetAvailableSubdmain(name)
    this.setState({ availableSubdomainInProcess: false })
    var businessInfo = this.state.businessInfo;
    if (response.Message === undefined) {
      businessInfo.SubDomain = response;

      this.setState({ businessInfo: businessInfo });
      return;
    }


  }

  CheckSubdomain = async (subDomain) => {

    this.setState({ isAvailable: true });
    var response = await EnterpriseService.CheckSubdomain(subDomain)
    this.setState({ isAvailable: false })

    if (response.Message === undefined) {
      this.setState({ subDomainError: !response })
      return;
    }

  }


  handleValue = (e, type) => {

    var businessInfo = this.state.businessInfo;
    var value = Utilities.SpecialCharacterEncode(e.target.value)

    switch (type.toUpperCase()) {

      case 'SN':
        businessInfo.StoreName = value;
        businessInfo.SubDomain = value;
        if (e.target.value.length > 3) {
          this.GetAvailableSubdmain(value)
        }
        break;
      case 'SD':
        businessInfo.SubDomain = e.target.value;
        this.CheckSubdomain(e.target.value)

      default:
        break;
    }

    this.MakePageName(e);

    this.setState({ businessInfo: businessInfo })

  }


  MakePageName = (e) => {

    var pageName = e.target.value
    pageName = pageName.trim();
    pageName = pageName.replace(/'/gi, "").replace(/ /gi, "-").replace(/\./g, "").replace(/#/gi, "");
    this.RemoveSpecialChars(e);
    this.state.businessInfo.PageName = pageName;
    // this.state.businessInfo.SubDomain = pageName;
    // this.setState({});
  }

  RemoveSpecialChars(e) {

    Utilities.RemoveSpecialChars(e);
    Utilities.RemoveDefinedSpecialChars(e);

  }

  handleBtnNext = () => {

    this.props.onClick(42, this.state.businessInfo, 'bi');
    this.props.goToStep(2);

  }

  componentDidMount() {

  }


  render() {
    return (
      <div className="align-items-center" >
        <div className="card pb-relative">
          <div className="p-4" >
            <h4 className='mb-4'>What would you like to name your store?</h4>
            <AvForm onValidSubmit={this.handleBtnNext}>

              <div className='form-input-d'>
                <AvField name="storeName" label="Store name" type="text" value={Utilities.SpecialCharacterDecode(this.state.businessInfo.StoreName)} onChange={(e) => this.handleValue(e, 'SN')}
                  validate={{
                    required: { value: this.props.isRequired, errorMessage: 'Please enter a store name' },
                    minLength: { value: 4, errorMessage: 'Your store name must be at least 4 characters' },
                  }}
                />
                {
                  ( this.state.availableSubdomainInProcess) && 
                <div className='store-loader-wrap' style={{ top:42}}>
                  <span className="comment-loader"><Loader type="Oval" color="#000" height={24} width={24} /></span>
                </div>
                }
               
              </div>
              {!Utilities.stringIsEmpty(this.state.businessInfo.StoreName) &&
               <div>
               { this.state.showEdit ?
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <a className='text-primary cursor-pointer'>{`${Utilities.SpecialCharacterDecode(this.state.businessInfo.SubDomain)}${Utilities.stringIsEmpty(this.state.businessInfo.SubDomain) ? "" : '.supershoply.com'}`}</a>
                    <a className='text-primary cursor-pointer' onClick={() => this.showEditFun()}><i className="fa fa-edit mr-1"></i>Edit</a>
                  </div>
                  :
                  <div className='form-input-d'>
                    <AvField name="emailAddress" type="text" onKeyUp={(e) => this.MakePageName(e)} value={Utilities.SpecialCharacterDecode(this.state.businessInfo.SubDomain)} onChange={(e) => this.handleValue(e, 'SD')}
                      class={this.state.subDomainError ? "is-touched is-dirty av-invalid is-invalid form-control":"form-control"}
                      validate={{
                        required: { value: this.props.isRequired, errorMessage: 'Your store name must be at least 4 characters' },
                        minLength: { value: 4, errorMessage: 'Your store URL must be at least 4 characters' },
                      }}
                    />
                   

                  <div className='store-loader-wrap'>
                    <span>.supershoply.com</span>
                    {
                    (this.state.isAvailable ) && 
                    <span className="comment-loader-s"><Loader type="Oval" color="#000" height={24} width={24} /></span>
                    }
                </div>
                <span className='invalid-feedback d-flex' style={{marginTop:'-10px', marginBottom:'10px'}}> {this.state.subDomainError ? 'A store with that URL already exists' : ''} </span>
                  </div>

  }
     <div className=" mb-3 color-7" >
     This is a temporary URL to start setting up your Supershoply store. Once you set up your store, you can choose to keep it as-is, or replace it with a custom domain name. <a className='text-primary cursor-pointer'>Learn more</a>
              </div>
                  </div>
              }
              <div className="d-flex justify-content-end align-items-center  px-4 btn-store-bottom w-100" >
                <Button disabled={this.state.isAvailable || this.state.availableSubdomainInProcess || this.state.subDomainError} className={`px-3 ${((this.state.isAvailable || this.state.availableSubdomainInProcess) || this.state.subDomainError) && 'btn btn-secondary disabled'}`} color="primary">Next</Button>
              </div>
            </AvForm>
          </div>
        </div>

      </div>
    );
  }
}

// var LoadingDiv = React.createClass({
//   render: function() {
//       return (
//         ''
//       );
//   }
// });

/*const LoadingDiv = () => (
  <div className='modal'>
        Hello, World!
    </div>
  )*/

export default BusinessInfo;
