import UrlConstant from '../helpers/URLConstants';
//import Constants from '../helpers/Constants';
import Config from '../helpers/Config';
import * as Utilities from  '../helpers/Utilities';
import * as APIUtilities from '../helpers/APIUtilities';

const enterpriseTypeIds = "3,4";
const descending = false;
// const pageSize = 10;



export const   Enterprise =  {
    "EnterpriseSetting" : {
    "CountryId": 0,
    "EnterpriseId": 0,
    "Name" : "",
    "TakeOnlineOrder": "",
    "MetaTitle": "",
    "MetaKeywords": "",
    "MetaDescription": "",
    "OtherTagCsv": "",
    "ShortDescription": "",
    "LongDescription": "",
    "Email": "",
    "Url": "",
    "Mobile1": "",
    "Mobile2": "",
    "Landline1": "",
    "Landline2": "",
    "SmsMaskingName": "",
    "OwnerName": "",
    "CommissionAmountTableBookingSourcePortal": "",
    "CommissionAmountTableBookingSourceEnterprise": "",
    "StandardCharges": "",
    "CashBackMinOrder": "",
    "CommissionPercentage": "",
    "StandardChargesExternal": "",
    "CashBackMinOrderEnterprise": "",
    "AppCommissionPercentage": "",
    "CashBack": "",
    "CashBackEnterprise": "",
    "CityIdsCsv": "",
    "Category": 0,
    "IsOwnEnterprise": "",
    "IsSmsAlertsOffered": "", //new order sms
    "ApplyGST" : "",
    "ParentId": 0,
    "Id":0,
    "EnterprisePage": {
        "PageName": "",
        "SubdomainName": ""
    },

    "CommissionHistory": {
        "CardFeesPortalInternal": "",
        "CashbackPortalInternal": "",
        "CashbackEnterpriseInternal": "",
        "CardFeesPortalExternal": "",
        "CashbackPortalExternal": "",
        "CashbackEnterpriseExternal": "",
    },

    "AgentEnterprise" : {
        "EnterpriseAgentId": 0,
        "CommissionPercentage": "",
        "CommissionAmountTableBookingSourcePortal": "",
        "CommissionPercentageSourceEnterprise": "",
        "CommissionAmountTableBookingSourceEnterprise": "",
    },

    "EnterpriseSettings" : {
        "AllowActivitySMS": "",
        "IsSupermealProfileAllowed": "",
        "IsSponsored": "",
    },

    "AddressEnterprise" : {

        "Label":"",
        "Address1":"",
        "AreaId":"",
        "Latitude":"",
        "Longitude":"",
    } 

},

"EnterpriseUser" : {
  
    "EUser" : {
        "Id" : 0,
        "Title": "Mr",
        "FirstName": "",
        "SurName": "",
        "PrimaryEmail": "",
        "Gender": "M",
        "CreatedBy": "",
        "RoleLevel": 0,

},
"Password" : "",
"ConfirmPassword": "",
"LoginUserName" : ""
},

"EnterpriseTypeId" : "3,4",
"IsChurned" : false,
"ChurnedNotes" : ""

}



export let  GetAll =  async(pageNo,pageSize,searchKeyword,chkChurned,chkAll) => {

    try{

            const response = await 
            fetch(UrlConstant.Enterprise + `${enterpriseTypeIds}/${pageNo}/${pageSize}/${descending}/${searchKeyword}/${chkChurned}/${chkAll}`, 
            {
                    method: 'GET',
                    headers: Config.headers,
            })
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
                return JSON.parse(result.Dictionary.Enterprises);
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '';
        }
}


export let  GetAllOnline =  async() => {

    try{

            const response = await 
            fetch(UrlConstant.Enterprise + `AllOnline`, 
            {
                    method: 'GET',
                    headers: Config.headers,
            })
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
                return JSON.parse(result.Dictionary.Enterprises);
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '';
        }
}

export let  Get =  async() => {

    try{

            const response = await 
            fetch(UrlConstant.Enterprise + Utilities.GetEnterpriseIDFromSessionObject(),
            {
                    method: 'GET',
                    headers: Config.headers,
            })
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
                return JSON.parse(result.Dictionary.EnterpriseWithDetail);
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '';
        }
}

export let IsPageNameAvailable = async (pageName) => {
    
    try{
        
        const response = await 
        
        fetch(UrlConstant.Enterprise + 'CheckPageName',
        {
            method: 'Post',
            headers: Config.headers,
            body:  '["' + Utilities.GetEnterpriseIDFromSessionObject() + '","' +pageName + '"]' ,
            
        }
        )


        const result = await response.json();
        
        if (!result.HasError && result !== undefined) {
    
            if (result.Dictionary.IsAvailable === true) {
                return '1';
            }
        }
    
        return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '0';

    }
    catch(e){
        console.log('error: ', e); 
        return []; 
    }
}


export let  ActiveSuspend =  async(enterpriseId, enterpriseTypeId,isActive) => {

    try{

            const response = await 
            
            fetch(UrlConstant.Enterprise  + 'ActiveSuspend/' + enterpriseId + '/' + enterpriseTypeId + '/' + isActive,
            {
                    method: 'PUT',
                    headers: Config.headers,
                }
            )
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
        
                if (result.Dictionary.IsUpdated === true) {
                        return '1';
                }
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '0';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '0';
        }
}


export let  Churned =  async(enterprise) => {

    try{

            const response = await 
            
            fetch(UrlConstant.Enterprise  + 'Churned',
            {
                    method: 'PUT',
                    headers: Config.headers,
                    body: JSON.stringify(enterprise),
                }
            )
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
        
                if (result.Dictionary.IsUpdated === true) {
                        return '1';
                }
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '0';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '0';
        }
}


export let  Delete =  async(enterpriseId, enterpriseTypeId) => {

    try{

            const response = await 
            
            fetch(UrlConstant.Enterprise + enterpriseId + '/' + enterpriseTypeId,
            {
                    method: 'DELETE',
                    headers: Config.headers,
                }
            )
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
        
                if (result.Dictionary.IsDeleted === true) {
                        return '1';
                }
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '0';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '0';
        }
}

export let  GetAgents =  async() => {

    try{

            const response = await 
            fetch(UrlConstant.EnterpriseAgent,
            {
                    method: 'GET',
                    headers: Config.headers,
            })
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
                return JSON.parse(result.Dictionary.EnterpriseAgent);
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '';
        }
}

export let  Save =  async(enterprise) => {

    try{

            const response = await 
            
            fetch(UrlConstant.Enterprise + "New",
            {
                    method: 'POST',
                    headers: Config.headers,
                    body: JSON.stringify(enterprise),
                }
            )
            
            const result = await response.json();
            
             if (!result.HasError && result !== undefined) {
                return JSON.parse(result.Dictionary.NewEnterpriseId);
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '0';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '0';
        }
}

export let  Update =  async(enterprise) => {

    try{

            const response = await 
            
            fetch(UrlConstant.Enterprise,
            {
                    method: 'Put',
                    headers: Config.headers,
                     body: JSON.stringify(enterprise),
                }
            )
            
            const result = await response.json();
            
            if (!result.HasError && result !== undefined) {
        
                if (result.Dictionary.IsUpdated === true) {
                        return '1';
                }
            }
        
            return result.HasError === true && result.ErrorCodeCsv !=='' ? result.ErrorCodeCsv : '0';
    
        }
        catch(e){
            console.log('error: ', e);  
            return '0';
        }
}

export let Create = async (data) => {
    console.log('data', data)
    try{
        const url = `${UrlConstant.Enterprise}Create`
        const response = await APIUtilities.Post(url, data)
        const result = await response.json();
        return result;
    }
    catch(e){
        console.log('error: ', e);  
    }

}


export let CheckSubdomain = async (subDomain) => {
   
    try{
        const url = `${UrlConstant.Enterprise}CheckSubdomain`
        const response = await APIUtilities.Post(url, subDomain)
        const result = await response.json();
        return result;
    }
    catch(e){
        console.log('error: ', e);  
    }

}


export let GetAvailableSubdmain = async (name) => {
   
    try{
        const url = `${UrlConstant.Enterprise}GetAvailableSubdmain`
        const response = await APIUtilities.Post(url, name)
        const result = await response.json();
        return result;
    }
    catch(e){
        console.log('error: ', e);  
    }

}


