// import logo from './logo.svg';
import './App.scss';
import React, { Component }  from 'react';
import Register from './Register/Register'
function App() {
  return (
    <>
    <Register/>
    </>
  );
}

export default App;
