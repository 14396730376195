import React, { Component } from 'react';
//import logo from '../../../assets/img/brand/logo.png'
import Loader from 'react-loader-spinner';
import StepWizard from "react-step-wizard";
import BusinessInfo from './BusinessInfo';
import BusinessType from './BusinessType';
import BusinessLocation from './BusinessLocation';
import CreateAccount from './CreateAccount';
import SocialLogin from './SocialLogin';
import ProgressBar from 'react-bootstrap/ProgressBar';
import GlobalData from '../helpers/GlobalData';
import * as CountryService from '../service/Country';
import * as BusinessTypeService from '../service/BusinessType';
import * as AuthService from '../service/Auth'

class Register extends Component {
  state = { interval: null }
  
  loading = () =>   <div className="page-laoder">
    <div> 
      <Loader type="Oval" color="#ed0000" height={50} width={50}/>  
      <div className="loading-label">Loading.....</div>
      </div>
  </div> 

  constructor(props) {
    super(props);

    this.state = {
      username: 'Bilal',
      password: '',
      secureTextEntry:true,
      errorUsername: '',
      errorPassword: '',
      errorMsg: '',
      classDisplay: 'no-display',
      type: 'password',
      score: 'null',
      step: "1",
      progressbarValue: 0,
      store: {},
      countryList: [],
      selectedCode: '',
      selectedCountryID: 0,
      showLoader: true,
      businessTypeList: [],
      selectedType: 0,
      active:3,
    };

    
    this.auth();
  }

  auth = async () => {

   const auth = await AuthService.getJWTToken();
   auth != "" && this.getAllCountries();
}

  getBusinessypes = async () => {
    try {
        let response = await BusinessTypeService.GetTypes()
        this.getCurrentCountry();
        if (response.length > 0) {

            this.setState({
              businessTypeList: response,
              selectedType: response[0]
  
            });
            
            
            return;
        }
        
            console.log('something went wrong')
    } catch (error) {
        console.log('something went wrong', error)
    }

    

  }

  getCurrentCountry = async () => {
    try {
        let response = await CountryService.getCurrentCountry()
        this.setState({ showLoader: false });
        if (Object.keys(response).length > 0) {
            this.setState({
              currentCountry: response.ip,
              selectedCurrentCountry: response.ip.country_code == this.state.selectedCode
            })

            return;
        }
        
       console.log('something went wrong')
    } catch (error) {
        console.log('something went wrong', error)
    }
  
  }
  

  getAllCountries = async () => {
    try {
        let response = await CountryService.getAllCountries()
        
        if (response.length > 0) {
            var sortCountries = response.sort((a, b) => a.SortOrder - b.SortOrder)
            
            this.setState({
                countryList: sortCountries,
                selectedCode: sortCountries[0].IsoCode2,
                selectedCountryID: sortCountries[0].Id,
                selectedCountryName: sortCountries[0].Name

            },()=>{

              this.getBusinessypes();
            })
            return;
        }
        
       console.log('something went wrong')
    } catch (error) {
        console.log('something went wrong', error)
    }

  }
  // showPackage=(i)=>{
  //   this.setState({
  //     active:!this.state.active
  //   })
  // }
  showPackage = (i) => {
    this.setState({
active:i
    });
    
//console.log('check i value', active);
    // let _this = this;
    // return function() {
    //   _this.setState({
    //     active: i
    //   });
    // }
  }
  goToStep =(e, value, type)=>{

    var storeInfo = this.state.store;

      switch (type.toUpperCase()) {
  
        case 'BI':
          storeInfo.Name = value.StoreName;
          storeInfo.SubDomain = value.SubDomain;
          storeInfo.PageName = value.PageName;

          break;
        case 'BL':
          storeInfo.CountryId = value;

          break;
          case 'BT':
            storeInfo.BussinessType  = value;
            break;
    
          case 'CA':
            storeInfo.FirstName = value.FirstName;
            storeInfo.SurName = value.SurName;
            storeInfo.Email = value.Email;
            storeInfo.Password = value.Password;
            
              break;
        default:
          break;
      }

      console.log("storeInfo", storeInfo);
      this.setState({step: e, store: storeInfo}, () => {
        if(type.toUpperCase() == 'CA' )this.SaveData();
      });
      
   }

  render() {

    if(this.state.showLoader)  return <div className='loader-m-p-center'><Loader type="Oval" color="red" height={50} width={50} /></div>

    return (
      <div>
      <div className="w-100 d-flex justify-content-start register-s-form flex-column align-items-center py-4 my-4 mx-auto bg-white rounded overflow-hidden"  style={{maxWidth:500,}}>
      <img className="lazy responsive-logo login-img mb-4" src="https://cloud.supershoply.com/images/logo.png" alt="logo"/>
   {/* <img  className="lazy responsive-logo login-img mb-4" src={GlobalData.enterprise_data.Supermeal_dev.Platform == "core" ? logo : GlobalData.enterprise_data.Supermeal_dev.baseImageUrl +"/images/logo.png"} alt="logo" /> */}
   <h2 className={this.state.step==1?'mb-4 px-4':'d-none'}>Start your free 14 days trail of Supershoply.</h2>
    <div className='w-100 px-4'> <ProgressBar now={this.state.step}  className="w-100"/></div>
                     {/* <p>Total Steps: {this.props.totalSteps}</p> */}
        <StepWizard initialStep={this.state.step}>
          <BusinessInfo  onClick={(e, value, type)=>this.goToStep(e, value, type)}/>
          <BusinessType onClick={(e, value, type)=>this.goToStep(e, value, type)}  businessTypeList= {this.state.businessTypeList} selectedType= {this.state.selectedType} /> 
          <BusinessLocation onClick={(e, value, type)=>this.goToStep(e, value, type)} countryList={this.state.countryList} selectedCode={this.state.selectedCode} selectedCountryID={this.state.selectedCountryID} selectedCountryName={this.state.selectedCountryName} />
          <SocialLogin onClick={(e, value, type)=>this.goToStep(e, value, type)} /> 
          <CreateAccount onClick={(e, value, type)=>this.goToStep(e, value, type)} storeInfo={this.state.store} />
          
  {/* <Step1>
    <div>BIlal</div>
  </Step1>
  <Step2>
  <div>Mannan</div>
  </Step2> */}

  {/* <Step5 /> */}
  {/* <WhateverComponentName /> */}
</StepWizard>
{/* <a className='btn btn-primary' onClick={()=>this.SaveData()}>savedata</a> */}
      </div>
      <div className='subscription-wrap p-5 card d-none'>
          <div className='subscription-items position-relative'>
            <div className=' d-flex align-items-center justify-content-center mb-4'>
              <div className='subscription-btns bg-secondary'>
                <button type="button" class={this.state.active == 1 ?"btn  btn-package mr-2 active":"btn btn-package mr-2"} onClick={()=>this.showPackage(1)} >Monthly</button>
                <button type="button" class={this.state.active == 2 ?"btn  btn-package active":"btn  btn-package"} onClick={()=>this.showPackage(2)}>Yearly (Save 10%)</button>
              </div>
            </div>
            <div className='subscription-tabs d-lg-none d-xl-none d-md-none '>
            <ul class="nav nav-tabs">
              <li class="nav-item flex-1">
                <a class="nav-link active " aria-current="page" onClick={()=>this.showPackage(3)}>BASIC</a>
              </li>
              <li class="nav-item flex-1">
                <a class="nav-link " onClick={()=>this.showPackage(4)}>SHOPIFY</a>
              </li>
              <li class="nav-item flex-1">
                <a class="nav-link"  onClick={()=>this.showPackage(5)}>ADVANCED</a>
              </li>
            </ul>
            </div>
            <div className='pricing-card row '>
            
              <div className=  {this.state.active == 3 ?'card-body pricing-card-f col-md-4 flex-column justify-content-between align-items-center  ':'d-md-flex d-none card-body pricing-card-f col-md-4 flex-column justify-content-between align-items-center'}>
                <div className='pricing-content d-flex flex-column justify-content-between align-items-center mb-5'>
                  <h4 className='mb-3'>Basic</h4>
                  <span className='font-14'>Everything you need to create your store, ship products, and process payments</span>
                </div>
                <div className='actual-price d-flex justify-content-between mb-2'>
                  <span>$</span>
                  <span>26</span>
                </div>
                <div className='color-7 font-14'>
                  <span>For a month</span>
                </div>
                <hr className='price-divide' />
                <div className='pricing-info-section mt-3 w-100'>
                  <h5>What is included on Basic:</h5>
                  <div className='feature-wrap mt-4'></div>
                  <ul className='mb-3'>
                    <li className='mb-2'>
                      <i class="fas fa-check mr-3 text-success font-14"></i>
                      <span className='font-16'>Basic reports</span>
                    </li>
                    <li className='mb-2'>
                      <i class="fas fa-user mr-3 text-success font-14"></i>
                      <span className='font-16'>2 staff accounts</span>
                    </li>
                    <li className='mb-2'>
                      <i class="fas fa-map-marker-alt  mr-3 text-success font-14"></i>
                      <span className='font-16'>Up to 4 inventory locations</span>
                    </li>
                  </ul>
                  <button className='try-btn-subscription w-100'>Try for free</button>
                </div>
              </div>


              <div className=  {this.state.active == 4 ? 'card-body pricing-card-f col-md-4  flex-column justify-content-between align-items-center':'d-md-flex d-none card-body pricing-card-f col-md-4  flex-column justify-content-between align-items-center'}>
                <div className='pricing-content d-flex flex-column justify-content-between align-items-center mb-5'>
                  <h4 className='mb-3'>Intermediate</h4>
                  <span className='font-14'>Level up your business with professional reporting and more staff accounts</span>
                </div>
                <div className='actual-price d-flex justify-content-between mb-2'>
                  <span>$</span>
                  <span>49</span>
                </div>
                <div className='color-7 font-14'>
                  <span>For a month</span>
                </div>
                <hr className='price-divide' />
                <div className='pricing-info-section mt-3 w-100'>
                  <h5>What is included on Intermediate:</h5>
                  <div className='feature-wrap mt-4'></div>
                  <ul className='mb-3'>
                    <li className='mb-2'>
                      <i class="fas fa-check mr-3 text-success font-14"></i>
                      <span className='font-16'>Professional reports</span>
                    </li>
                    <li className='mb-2'>
                      <i class="fas fa-user mr-3 text-success font-14"></i>
                      <span className='font-16'>5 staff accounts</span>
                    </li>
                    <li className='mb-2'>
                      <i class="fas fa-map-marker-alt  mr-3 text-success font-14"></i>
                      <span className='font-16'>Up to 5 inventory locations</span>
                    </li>
                  </ul>
                  <button className='try-btn-subscription-b w-100'>Try for free</button>
                </div>
              </div>


              <div className=  {this.state.active == 5 ?'card-body pricing-card-f col-md-4 d-flex flex-column justify-content-between align-items-center':'d-md-flex d-none card-body pricing-card-f col-md-4  flex-column justify-content-between align-items-center'}>
                <div className='pricing-content d-flex flex-column justify-content-between align-items-center mb-5'>
                  <h4 className='mb-3'>Advanced</h4>
                  <span className='font-14'>Get the best of Shopify with custom reporting and our lowest transaction fees</span>
                </div>
                <div className='actual-price d-flex justify-content-between mb-2'>
                  <span>$</span>
                  <span>299</span>
                </div>
                <div className='color-7 font-14'>
                  <span>For a month</span>
                </div>
                <hr className='price-divide' />
                <div className='pricing-info-section mt-3 w-100'>
                  <h5>What is included on advanced:</h5>
                  <div className='feature-wrap mt-4'></div>
                  <ul className='mb-3'>
                    <li className='mb-2'>
                      <i class="fas fa-check mr-3 text-success font-14"></i>
                      <span className='font-16'>Custom report builder</span>
                    </li>
                    <li className='mb-2'>
                      <i class="fas fa-user mr-3 text-success font-14"></i>
                      <span className='font-16'>15 staff accounts</span>
                    </li>
                    <li className='mb-2'>
                      <i class="fas fa-map-marker-alt  mr-3 text-success font-14"></i>
                      <span className='font-16'>Up to 8 inventory locations</span>
                    </li>
                  </ul>
                  <button className='try-btn-subscription-b w-100'>Try for free</button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}

// var LoadingDiv = React.createClass({
//   render: function() {
//       return (
//         ''
//       );
//   }
// });

/*const LoadingDiv = () => (
  <div className='modal'>
        Hello, World!
    </div>
  )*/

export default Register;
