import Config from '../helpers/Config';
import Constants from './Constants';
// import * as Utilities from  '../helpers/Utilities';


export let Get = async (url) => {
    
    try{
        
        const response = await 
        fetch(url,
        {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'App': "SuperCenter",
                'Authorization': "Bearer " + localStorage.getItem(Constants.Session.JWT_TOKEN),
                'AuthenticationTicket': localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET) === "null" ? "" : localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET),
                'ImpersonatorID': localStorage.getItem(Constants.Session.IMPERSONATORID) === "null" ? "" : localStorage.getItem(Constants.Session.IMPERSONATORID)
            }
        }
        )
      return response;
    
    }
    catch(e){
        console.log('error: ', e); 
        return []; 
    }
}



export let Post = async(url, body) => {

    try{

        const response = await 
        
        fetch(url,
        {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'App': "SuperCenter",
                    'Authorization': "Bearer " + localStorage.getItem(Constants.Session.JWT_TOKEN),
                    'AuthenticationTicket': localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET) === "null" ? "" : localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET),
                    'ImpersonatorID': localStorage.getItem(Constants.Session.IMPERSONATORID) === "null" ? "" : localStorage.getItem(Constants.Session.IMPERSONATORID)
                },
                body: JSON.stringify(body),
            }
        )
        return response;
    }
    catch(e){
        console.log('error: ', e);  
        return {};
    }

}


export let Put = async(url, body) => {

    try{


        const response = await 
    
        fetch(url,
        {
                method: 'PUT',
                headers: Config.headers,
                body: JSON.stringify(body),
            }
        )
        return response;
        
    }
    catch(e){
        console.log('error: ', e);  
        return '0';
    }

}



export let  Delete =  async(url,body) => {
    try{

            const response = await 
            
            fetch(url,
            {
                    method: 'DELETE',
                    headers: Config.headers,
                    body: JSON.stringify(body),
                }
            )

            return response;
    
        }
        catch(e){
            console.log('error: ', e);  
            return '0';
        }
}
