import UrlConstant from '../helpers/URLConstants';
import Config from '../helpers/Config';
import Constants from '../helpers/Constants';

export let getAllCountries = async () => {
    try {
        const response = await
            fetch(UrlConstant.COUNTRY,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'App': "SuperCenter",
                        'Authorization': "Bearer " + localStorage.getItem(Constants.Session.JWT_TOKEN),
                        'AuthenticationTicket': localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET) === "null" ? "" : localStorage.getItem(Constants.Session.AUTHENTICATION_TICKET),
                        'ImpersonatorID': localStorage.getItem(Constants.Session.IMPERSONATORID) === "null" ? "" : localStorage.getItem(Constants.Session.IMPERSONATORID)
                    },
                })
        const result = await response.json();
        if (!result.HasError && result !== undefined) {
            return result
        }

        return result.HasError === true && result.ErrorCodeCsv !== '' ? result.ErrorCodeCsv : '';
    }
    catch (e) {
        console.log('error: ', e);
        return [];
    }
}
export let getActiveCountries = async () => {
    try {
        const response = await
            fetch(UrlConstant.ACTIVE_COUNTRIES,
                {
                    method: 'GET',
                    headers: Config.headers,
                })
        const result = await response.json();
        if (!result.HasError && result !== undefined) {
            return result
        }

        return result.HasError === true && result.ErrorCodeCsv !== '' ? result.ErrorCodeCsv : '';
    }
    catch (e) {
        console.log('error: ', e);
        return [];
    }
}
export let updateCountryConfig = async (countryId, configuration) => {
    try {
        const response = await
            fetch(UrlConstant.UpdateConfig + "/" + countryId,
                {
                    method: 'PUT',
                    headers: Config.headers,
                    body: JSON.stringify(configuration),
                })
        const result = await response.json();
        if (!result.HasError && result !== undefined) {
            return result
        }

        return result.HasError === true && result.ErrorCodeCsv !== '' ? result.ErrorCodeCsv : '';
    }
    catch (e) {
        console.log('error: ', e);
        return [];
    }
}

export let getCurrentCountry = async () => {
    try {
        const response = await
            fetch("https://ip.nf/me.json",
                {
                    method: 'GET',
                })
                const result = await response.json();
                return result;
    }
    catch (e) {
        console.log('error: ', e);
        return [];
    }
}


