import UrlConstant from '../helpers/URLConstants';
import * as APIUtilities from '../helpers/APIUtilities';
import Config from '../helpers/Config';
import * as Utilities from  '../helpers/Utilities';


export let GetTypes = async () => {
    
    try{
       
        const url = `${UrlConstant.BusinessType}`
        const response = await APIUtilities.Get(url)
        const result = await response.json();
        return result;
     }
    catch(e){
        console.log('error: ', e);  
    }

}

