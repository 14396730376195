import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Tooltip, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Alert } from 'reactstrap';
import ReactFlagsSelect from "react-flags-select";
import GlobalData from '../helpers/GlobalData';
import { MdArrowBack, MdDragIndicator, MdDragHandle } from "react-icons/md";
import * as CountryService from '../service/Country';
class BusinessLocation extends Component {
  state = { interval: null }
  
  loading = () =>   <div className="page-laoder">
    <div> 
      <Loader type="Oval" color="#ed0000" height={50} width={50}/>  
      <div className="loading-label">Loading.....</div>
      </div>
  </div> 

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      secureTextEntry:true,
      errorUsername: '',
      errorPassword: '',
      errorMsg: '',
      classDisplay: 'no-display',
      type: 'password',
      score: 'null',
      selectedCode:  this.props.selectedCode,
      countryList: this.props.countryList,
      selectedCountryID: this.props.selectedCountryID, 
      selectedCountryName: this.props.selectedCountryName, 
      selectedId:'',
      currentCountry: {},
      selectedCurrentCountry: true,
      
    };
    this.getCurrentCountry();

  }

  setSelected = (code) =>{

    var countries = this.state.countryList.find(obj => {
      return obj.IsoCode2 == code;
    });
    this.setState({ selectedCode: code, selectedCountryID: countries.Id, selectedCountryName: countries.Name,  selectedCurrentCountry: this.state.currentCountry.country_code == code})
}



getCurrentCountry = async () => {
  try {
      let response = await CountryService.getCurrentCountry()
      if (Object.keys(response).length > 0) {
          this.setState({
            currentCountry: response.ip,
            selectedCurrentCountry: response.ip.country_code == this.state.selectedCode
          }, () => {
            this.setSelected(response.ip.country_code);
          })
          
          return;
      }
      
     console.log('something went wrong')
  } catch (error) {
      console.log('something went wrong', error)
  }

  

}


  componentDidMount() {
   
    
  }


  render() {
    return (
      <div  className="align-items-center" >
        <div className="card pb-relative" style={{minHeight:400}}>
        <div className=" p-4" >
        <h4 className='mb-2'>Where will your store be located?</h4>
        <p>We’ll use your location to set up your default currency, recommended services, timezone and more.</p>
        <AvForm>
          <div class="form-group" style={{ flex: "1" }}>
              <ReactFlagsSelect
                  searchPlaceholder="Search countries"
                  searchable
                  selected={this.state.selectedCode}
                  onSelect={(code) => this.setSelected(code)}
              />
            
              </div>
            
         {!this.state.selectedCurrentCountry && <div className="alert alert-warning mb-3">
          <div><div>It looks like you’re located in {this.state.currentCountry.country}. Are you sure you want to create a Supershoply store in {this.state.selectedCountryName}? Setting up your store in the wrong country may lead to the following issues:</div>
          <ul className='py-3'><li><span>Tax and duty obligations: </span><span>{this.state.selectedCountryName} may require you to file additional tax and duty financial obligations to operate your store.</span></li><li><span>Payment issues: </span><span>Limited availability of Supershoply Payments in {this.state.selectedCountryName} may cause payment processing issues or delays.</span></li><li><span>Shipping issues: </span><span>Limited shipping availability in {this.state.selectedCountryName} may result in higher costs or delays.</span></li><li><span>Localization: </span><span>By default, your language and currency for your store will be set to match {this.state.selectedCountryName}. You may experience reduced marketing or sales performance if your customer base is from {this.state.currentCountry.country}.</span></li><li><span>Supershoply benefits: </span><span>You may no longer be eligible for Supershoply products and services like Supershoply Capital, Supershoply Balance, partner ad credit, and select third-party Apps and Themes and more.</span></li><li><span>Terms of service: </span><span>Supershoply’s terms of service require that your store location be accurate at all times. Breach of these terms may result in suspension or termination of your Supershoply account.</span></li></ul></div>
        </div>}
        <div className="d-flex justify-content-end align-items-center  px-4 btn-store-bottom w-100">
        <a className='mr-auto cursor-pointer d-flex align-items-center'  onClick={()=>this.props.goToStep(2)}><MdArrowBack size={20}  className="mr-2"/>Back</a>
          {/* <Button className='mr-2 btn btn-secondary px-3' >Cancel</Button>  */}
          <Button color="primary"  className=' px-3' onClick={()=>this.props.goToStep(5) + this.props.onClick(75, this.state.selectedCountryID, 'bl')} >Next</Button>
        </div>
        </AvForm>
        </div>
        </div>

    </div>
    );
  }
}

// var LoadingDiv = React.createClass({
//   render: function() {
//       return (
//         ''
//       );
//   }
// });

/*const LoadingDiv = () => (
  <div className='modal'>
        Hello, World!
    </div>
  )*/

export default BusinessLocation;
