// import UrlConstant from './URLConstants';
import Constants from './Constants';
// import Config from './Config';
// // import { ToastContainer, toast } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';

//  const moment = require('moment-timezone');
// const regExpDecimal = /^[0-9]+(\.[0-9]{0,2})?$/;
// // a global month names array
// const gsMonthNames = [
//     'January',
//     'February',
//     'March',
//     'April',
//     'May',
//     'June',
//     'July',
//     'August',
//     'September',
//     'October',
//     'November',
//     'December'];


export let stringIsEmpty = (str) => {
    return (!str || /^\s*$/.test(str));
};

// export let generatePhotoURL = (photoName) => {

//     return Config.Setting.baseImageURL + photoName;

// }

// export let generatePhotoURLFromJson = (photoName, enterpriseId) => {

//     return `${Config.Setting.baseImageURL}/enterprise/${enterpriseId}/images/${photoName}`;

// }


// export let generatePhotoLargeURL = (photoName, forceNull, isCover) => {
//     if (photoName === null || photoName === "" || photoName === undefined) {
//         if (forceNull === true) { return "" }
//         return this.generatePhotoThumbURL(photoName, forceNull, isCover)
//     }
//     return ((isCover !== undefined && isCover === true) ? UrlConstant.ThumbImageURL.replace("images", "uncropped-images") : UrlConstant.LargeImageURL) + photoName.substring(0, 3) + "/" + photoName.substring(3, 6) + "/" + photoName;
// }

export let RemoveDefinedSpecialChars = (e) => {

     let initVal = e.target.value.replace(/"/gi, "").replace(/\\/gi, "");

     if (e.keyCode !== 8) {
         initVal  = initVal +  String.fromCharCode(e.keyCode)
      }
     else{
         initVal = initVal.substr(0, e.target.value.length-1)

      } 

    let specialChars = ";/:^#|`~[]{}<>?";

    for (var i = 0; i < specialChars.length; i++) {
        initVal = initVal.replace(new RegExp("\\" + specialChars[i], 'gi'), "");
     }

    e.target.value = initVal;
   
 }

export let RemoveSpecialChars = (e) => {

    let initVal = e.target.value.replace(/"/gi, "").replace(/\\/gi, "");

    let specialChars = "!@$^&%*()+=[]/{}|:<>?,.*;";

    for (var i = 0; i < specialChars.length; i++) {
        initVal = initVal.replace(new RegExp("\\" + specialChars[i], 'gi'), "");
    }

    e.target.value = initVal;

}


// export let FormatCurrency = (number, decimals) => {
//     var separator = "."; // Default to period as decimal separator
//     number = Number(number);
//     decimals = (decimals === undefined || decimals === null) ? 2 : decimals; // Default to 2 decimals
//     var divider = (decimals === 2) ? 100 : 1;

//     var value = "";

//     if (Number(decimals) === 0) {
//         value = number.toLocaleString().split(separator)[0];
//     }
//     else {

//         if (number.toLocaleString().split('.').length > 2) {
//             value = (Math.floor(number * (1000)) / divider).toLocaleString();
//         }
//         else {

//             value = number.toLocaleString();
//         }
//         value = value.toLocaleString().split(separator)[0] + ((value.toLocaleString().split('.').length > 1) ? "." + ((value.split('.')[1].length === 1) ? value.split('.')[1] + "0" : value.split('.')[1].substr(0, 2)) : ".00");
//     }

//     return value;
// }


// export let ValidateDecimals = (e) => {

//     let value = e.target.value;
//     // remove all characters that aren't digit or dot
//     value = value.replace(/[^0-9.]/g, '');
//     // replace multiple dots with a single dot
//     value = value.replace(/\.+/g, '.');
//     value = value.replace(/(.*\.{[0-9][0-9][0-9]}?).*/g, '$1');

//     // replace multiple zeros with a single one
//     value = value.replace(/^0+(.*)$/, '0$1');
//     // remove leading zero
//     value = value.replace(/^0([^.].*)$/, '$1');

//     e.target.value = value;
// }


// export let FormatCsv = (csv, csvSeperator) => {

//     if (csv === "") return csv;

//     if (csv.lastIndexOf(csvSeperator) !== -1) {
//         csv = csv.substring(0, csv.length - csvSeperator.length);
//     }

//     return csv;
// }

// export let GetEnterpriseIDFromSession = () => {

//     let userObject = JSON.parse(localStorage.getItem(Constants.Session.USER_OBJECT))
//     return userObject.Enterprise.Id
// }

export let GetEnterpriseIDFromSessionObject = () => {

    return localStorage.getItem(Constants.Session.ENTERPRISE_ID);
}
// export let generatePhotoThumbURL = (photoName, forceNull, isCover) => {
//     if (photoName === null || photoName === "") {
//         if (forceNull === true) {
//             return "";
//         }
//         photoName = "000000000_no-image.jpg";
//     }
//     return ((isCover !== undefined && isCover === true) ? UrlConstant.ThumbImageURL.replace("images", "uncropped-images") : UrlConstant.ThumbImageURL) + photoName.substring(0, 3) + "/" + photoName.substring(3, 6) + "/" + photoName;
// }

// export let GetDate = (dateTimeValue) => {
//     var date = moment();
//     var momentDate = moment.tz(date, Config.Setting.timeZone);
//     if (!stringIsEmpty(dateTimeValue) && moment.isMoment(dateTimeValue)) {
//         momentDate = moment(dateTimeValue.format(Constants.DATE_FORMATE_DD_MM_YYYY), Config.Setting.timeZone);
//     }
//     return momentDate;
// }

// export let GetDateTimeDefaultFormat = (dateTimeValue) => {
   
//     var momentDate = ''
//     if (!stringIsEmpty(dateTimeValue)) {
//        momentDate = moment(dateTimeValue).format('YYYY-MM-DD HH:mm:ss');
//     }
    
//     return momentDate;
// }

// export let FormatDate = (stringDate) => {
//     // stringDate may be json date or string date
//     return moment(stringDate);
// }

// export let GetObjectArrId = (id, arrObject) => {

//     var rowId = "-1";
//     for (var arrobj = 0, arrlen = arrObject.length; arrobj < arrlen; arrobj++) {
//         if (Number(arrObject[arrobj].Id) === Number(id) || Number(arrObject[arrobj].ID) === Number(id) || Number(arrObject[arrobj].id) === Number(id)) {
//             rowId = arrobj;
//             break;
//         }
//     }
//     return rowId;
// }

// export let SortByName = (x, y) => {
//     return ((x.Name.toLowerCase() === y.Name.toLowerCase()) ? 0 : ((x.Name.toLowerCase() > y.Name.toLowerCase()) ? 1 : -1));
// }

// export let isExistInCsv = (val, csv, seperator) => {

//     if ((csv === "") || (val === "")) return false;

//     var arrList = FormatCsv(csv, seperator).split(seperator);
//     var flag = false;

//     arrList.forEach(value => {
//         if (value === String(val)) {
//             flag = true;
//         }
//     })

//     return flag;
// }

// export let GetRoleName = (roleId) => {

//     switch (roleId) {
//         case 0:
//             return "Consumer";
//         case 1:
//             return "System_Admin";
//         case 2:
//             return "System_Operator";
//         case 3:
//             return "Agent";
//         case 4:
//             return "Enterprise_Admin";
//         case 5:
//             return "Enterprise_Manager";
//         case 6:
//             return "Enterprise_User";
//         case 7:
//             return "Corporate_Admin";
//         case 8:
//             return "Anonymous";
//         case 9:
//             return "Advertiser";
//         case 14:
//             return "Foortal_Enterprise_Admin";
//         default:
//             return "";
//     }
// }

// export let HasPermission = (roleLevel, permission) => {

//     try {
//         let permissions = null;

//         if (!stringIsEmpty(localStorage.getItem(Constants.Session.ALL_PERMISSION))) {
//             permissions = JSON.parse(localStorage.getItem(Constants.Session.ALL_PERMISSION))
//         }

//         return (permissions.hasOwnProperty(permission)) && (permissions[permission].split(',').indexOf(roleLevel.toString()) > -1);

//     }
//     catch (e) {
//         return false;
//     }

// }

export let SpecialCharacterDecode = (text) => {

    if (stringIsEmpty(text)) {
        return text;
    }

    text = text.replace(/&#34;/gi, '"');
    text = text.replace(/&#39;/gi, "'");
    text = text.replace(/<br>/gi, "\n");
    text = text.replace(/&#96;/gi, '`');
    text = text.replace(/&#145;/gi, '‘');
    text = text.replace(/&#146;/gi, '’');
    text = text.replace(/gi&#147;/gi, '“');
    text = text.replace(/&#148;/gi, '”');
    text = text.replace(/&lt;/gi, "<");
    text = text.replace(/&gt;/gi, ">");
    text = text.replace(/&#47;/gi, "/");
    text = text.replace(/&#43;/gi, "+");
    text = text.replace(/&amp;#39;/gi, "'");
    text = text.replace(/&amp;/gi, "&");
    text = text.replace(/amp;/gi, "");
    return text;
}

// export let convertNewLinetoHTMLTag = (text) =>{
//     text = text.replace('\n','<br>').trim()
//     return text;
// }
// export let removeExtraSpaces = (text) => {

//     if (stringIsEmpty(text)) {
//         return text;
//     }

//     text = text.replace(/^\s+|\s+$/g, "");
    
//     return text;
// }
// export let replaceUrl = (text) => {

//     if (stringIsEmpty(text)) {
//         return text;
//     }

//     text = text.replace(/^(https?|ftp|http):\/\//, "");
//     text = text.replace("http:", "");
//     text = text.replace("https:", "");
//     text = text.replace("ftp:", "");
//     return text;
// }

export let SpecialCharacterEncode = (text) => {

    if (stringIsEmpty(text)) {
        return text;
    }
    text = text.replace(/\r?\n|\r/g, "<br>");
    text = text.replace(/"/gi, "&#34;");
    text = text.replace(/'/gi, "&#39;");
    text = text.replace(/`/gi, "&#96;");
    text = text.replace(/‘/gi, "&#145;");
    text = text.replace(/’/gi, "&#146;");
    text = text.replace(/“/gi, "&#147;");
    text = text.replace(/”/gi, "&#148;");
    text = text.replace(/\//gi, "&#47;");
    text = text.replace(/\\/gi, "&#%5C");
    text = text.replace(/</gi, "&lt;");
    text = text.replace(/>/gi, "&gt;");
    text = text.replace(/(?:\\[rn]|[\r\n]+)+/g, "<br>");
    text = text.replace(/\xA0/g, " ");
    text = text.replace(/%x000A/g, ""); //U+000A 
    text = text.replace(/%x000C/g, "");//U+000C

    return text;
}

// export let GetDateDifferenceInTime = (date) => {

//     date = new Date(date);
//     var date2 = new Date(moment.tz(Config.Setting.timeZone).format("YYYY-MM-DDTHH:mm:ss"));

//     var sec = date2.getTime() - date.getTime();
//     if (isNaN(sec)) {
//         //alert("Input data is incorrect!");
//         return '';
//     }

//     if (sec < 0) {
//         //alert("The second date ocurred earlier than the first one!");
//         return '0  sec ago';
//     }

//     var second = 1000, minute = 60 * second, hour = 60 * minute, day = 24 * hour;
//     var days = 0, hours = 0, minutes = 0, seconds = 0;
//     days = Math.floor(sec / day);
//     sec -= days * day;
//     hours = Math.floor(sec / hour);
//     sec -= hours * hour;
//     minutes = Math.floor(sec / minute);
//     sec -= minutes * minute;
//     seconds = Math.floor(sec / second);

//     if (days >= 7) {
//         var month = gsMonthNames[date.getMonth()].substr(0, 3);
//         day = (date.getDate().toString().length === 1) ? "0" + date.getDate() : date.getDate();
//         var year = date.getFullYear();
//         return day + " " + month + " " + year;
//         //return this.GetDateFormat(date);
//     } else

//         if (days > 1 && days < 7) {
//             return days + ' days ago';
//         } else if (days === 1) {
//             return days + ' day ago';
//         } else if (hours > 1) {
//             return hours + '  hours ago';
//         } else if (hours === 1) {
//             return hours + ' hour ago';
//         } else if (minutes > 1) {
//             return minutes + ' mins ago';
//         } else if (minutes === 1) {
//             return minutes + ' min ago';
//         } else if (seconds >= 0) {
//             return seconds + '  sec ago';
//         }
//     return '0  sec ago';
// }

// export let notify = (msg, t) => {
//     if (t === 's')
//         toast.success(msg, { position: "bottom-right", autoClose: 4000 });
//     else if (t === 'e')
//         toast.error(msg, { position: "bottom-right", autoClose: 4000 });
// };

// export let IsNumber = (number) => {

//     if (!regExpDecimal.test(Number(number))) {
//         return false;
//     }
//     return true;
// }


// export let IsValidPostCodeUK = (p) => {
//     var postcodeRegEx = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/i;
//     return postcodeRegEx.test(p);
// }

// export let FormatPostCodeUK = (p) => {
//     p = p.replace(/\r?\n|\r/g, "");
//     if (IsValidPostCodeUK(p)) {
//         var postcodeRegEx = /^([A-Z]{1,2}\d{1,2}[A-Z]?)\s*(\d[A-Z]{2})$/i;
//         return p.replace(postcodeRegEx, "$1 $2");
//     } else {
//         return p;
//     }
// }

// export let SetSessionExpired = (s) => {

//     if (s.indexOf("[F000022]") !== -1) {

//         this.ClearSession();
//         window.location.href = "/login"
//     }

// }
// export let GetResourceValue = (platform, coreValue, shoplyValue) => {
//     return platform == 'core' ? coreValue : shoplyValue
// }

// export let ClearSession = (s) => {


//     localStorage.removeItem(Constants.Session.AUTHENTICATION_TICKET);
//     localStorage.removeItem(Constants.Session.USER_OBJECT);
//     localStorage.removeItem(Constants.Session.ALL_PERMISSION);
//     localStorage.removeItem(Constants.Session.ENTERPRISE_ID);
//     localStorage.removeItem(Constants.Session.ENTERPRISE_NAME);
//     localStorage.removeItem(Constants.Session.IMPERSONATORID);
//     localStorage.removeItem(Constants.Session.PARENTIMPERSONATORID);
//     localStorage.removeItem(Constants.Session.ADMIN_OBJECT);
//     localStorage.removeItem(Constants.MEMBERSHIP_ID);
//     localStorage.removeItem(Constants.Session.IS_MENU_MODIFIED);
//     localStorage.removeItem(Constants.Session.SESSION_START_AT);
//     // window.location.href = "/login"

// }

// export let urlValidation = (url) =>{
//     var regex = /^(ftp|http|https):\/\/[^ "]+$/
//     var valid = regex.test(url);
//     return valid
// }

// export let numberValidation = (number) =>{
//     var regex = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/
//     var valid = regex.test(number);
//     return valid
// }

// export let postDateFormate = (date) => {

//     var momentDate = ''
//     if (!stringIsEmpty(date)) {
//        momentDate = moment(date).format("YYYY/MM/DD HH:mm:ss");
//     } else {
//         momentDate = moment(moment().format("YYYY/MM/DD HH:mm:ss"));
//     }
   
//     return momentDate;
// }

// export let getDiscountPercentage = (price, PromoPrice) => {
//     var discountPercentage = ((price - PromoPrice) / price) * 100;
//     try {
//         var strNumber = discountPercentage.toString().split('.');
//         if (strNumber.length > 1) {
//             return strNumber[0] + "." + strNumber[1].substring(0, 2);
//         }
//         return strNumber[0];
//     }
//     catch (e) {
//         return discountPercentage.toFixed(2);
//     }

// }

// export let convertToSIUnit = (num, digits) => {
    
//     try {
//         if (num > 999 && num < 1000000) {
//             return ((num / 1000).toFixed(digits)) + 'K'; // convert to K for number from > 1000 < 1 million
//         } else if (num > 1000000) {
//             return ((num / 1000000).toFixed(digits)) + 'M'; // convert to M for number from > 1 million
//         } else if (num <= 999) {
//             return num.toFixed(); // if value < 1000, nothing to do
//         }
//         return num.toFixed();
//     }
//     catch (e) {
//         console.log("error in ConvertToSIUnit ", e.message)
//         return num;
//       }
// }


