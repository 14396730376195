import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Tooltip, FormGroup, Label, Button, Modal, ModalBody, ModalFooter, ModalHeader, Table, Alert } from 'reactstrap';
import ReactFlagsSelect from "react-flags-select";
// import GlobalData from '../../../helpers/GlobalData';
import { MdArrowBack, MdDragIndicator, MdDragHandle } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
class BusinessType extends Component {
  state = { interval: null }
  
  loading = () =>   <div className="page-laoder">
    <div> 
      <Loader type="Oval" color="#ed0000" height={50} width={50}/>  
      <div className="loading-label">Loading.....</div>
      </div>
  </div> 

  constructor(props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      secureTextEntry:true,
      errorUsername: '',
      errorPassword: '',
      errorMsg: '',
      classDisplay: 'no-display',
      type: 'password',
      score: 'null',
      businessTypeList: this.props.businessTypeList,
      selectedType: this.props.selectedType,

    };
   

  }

  handleMenuSelection = (type) => {
      this.setState({selectedType: type});
  }

  componentDidMount() {
    
  }

   render() {


    return (
      <div  className="align-items-center" >
      <div className="card pb-relative" style={{minHeight:350}}>
        <div className=" p-4" >
        <h4 className='mb-4'>Choose an option which best define your business.</h4>
     
        <AvForm>
      
        <div className='b-type-dropdown'>
        <Dropdown>
                  <Dropdown.Toggle variant="Secondary" id="dropdown-custom-components">
                  {this.state.businessTypeList.length > 0 ? this.state.selectedType.Name : "Select Business Type"}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>

                  {this.state.businessTypeList.map((type, index) => (
                     <Dropdown.Item key={type.Id} onClick={() => this.handleMenuSelection(type)}>{type.Name}</Dropdown.Item>
                    ))}
                
                  </Dropdown.Menu>
              </Dropdown>
        </div>
               
      
        <div className="d-flex justify-content-end align-items-center  px-4 btn-store-bottom w-100">
        <a className='mr-auto cursor-pointer d-flex align-items-center' onClick={()=>this.props.goToStep(1) + this.props.onClick(1, this.state.selectedType.Id, 'bi')}><MdArrowBack size={20}  className="mr-2"/>Back</a>
       {/* <Button className='mr-2 btn btn-secondary px-3' >Cancel</Button>  */}
          {/* <Button className='btn btn-primary  px-3' onClick={()=>this.props.goToStep(3)}>Next</Button> */}
          <Button color="primary" className='px-3' onClick={()=>this.props.goToStep(3) + this.props.onClick(50, this.state.selectedType.Id, 'bt')}>Next</Button>
        </div>
      </AvForm>
        </div>
        </div>

    </div>
    );
  }
}

// var LoadingDiv = React.createClass({
//   render: function() {
//       return (
//         ''
//       );
//   }
// });

/*const LoadingDiv = () => (
  <div className='modal'>
        Hello, World!
    </div>
  )*/

export default BusinessType;
